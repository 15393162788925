import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

class App extends Component {
    constructor() {
        super();
        this.state = { markdown: '' };
    }

    getMD() {
        // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
        fetch("texts/" + this.props.file + ".txt").then(
            res => res.text()
        ).then(
            text => {
                if(text !== this.state.markdown) {
                    this.setState({ markdown: text })
                }
                else {
                    //text is the same, do nothing
                }
            }
        );
    }

    componentDidMount() {
        this.getMD()
    }

    componentDidUpdate() {
        this.getMD()
      }

    render() {
        return (
            <div>
                {/* filter text, when there is no .md-file, an error occurs starting with <!DOCTYPE html> */}
                {this.state.markdown.startsWith("<!DOCTYPE html>") ? 'Er is geen metadata beschikbaar van deze kaartlaag': <ReactMarkdown children={this.state.markdown} />}
            </div>
        );
    }
}

export default App;