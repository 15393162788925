import { loadModules } from 'esri-loader';
export function basemaptoggleWidget(view,position){
  loadModules([
      "esri/widgets/BasemapToggle",
      "esri/layers/WMTSLayer",
      "esri/Basemap",
    ]).then(([BasemapToggle,WMTSLayer,Basemap]) => {

      const mapBaseLayerbasemapLufoNL = new WMTSLayer({
        url: "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0?",
        copyright: "<a target='_top' href='https://www.beeldmateriaal.nl/'>beeldmateriaal.nl</a>",
        activeLayer: {
          id: "Actueel_orthoHR"
        }
      });
      const basemapLuchtfotoNL = new Basemap({
        baseLayers: [mapBaseLayerbasemapLufoNL],
        title: 'luchtfoto',
        id: 'luchtfoto',
        thumbnailUrl: 'https://js.arcgis.com/4.21/esri/images/basemap/hybrid.jpg'
      });

      // start measure widget
      const widgetItem = new BasemapToggle({
        view: view,
        nextBasemap: basemapLuchtfotoNL,
        visibleElements: {
          title: true
        }
      });

      //popup is moved to right bottom
      view.ui.add(widgetItem, { position: position });


    }).catch((err) => console.error(err));
}