import React, { Fragment,useEffect,useState } from "react";
import './styling/app.scss';
import './styling/map/map.scss';
import EsriMap from "./components/EsriMap";


function App() {

  const [configLoading, setConfigLoading] = useState(true);
  const [config, setConfig] = useState(null);


  useEffect(() => {
    getConfig();

  }, []);

  async function getConfig() {

      let request = new XMLHttpRequest();
      request.open(
        "GET", `${process.env.PUBLIC_URL}/config/mapconfig.json`
      );
      request.send();
      request.onload = () => {
        if (request.status === 200) {
            setConfig(request.response)
            setConfigLoading(false);
        } else {

          setConfigLoading(false);
        }
      };
      request.onerror = () => {

        setConfigLoading(false);
      };
  }

  // List of all widgets: https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-Widget.html

  const mapconfig= JSON.parse(config)

  return (
    <Fragment>
      {configLoading ? <wb-loader></wb-loader> :
        <Fragment>
          <wb-header small wblogo apptitle={mapconfig.title}>
          </wb-header>

          <main className="webmap-container">
            <div className="webmap-container-inner">
              <EsriMap mapConfig={mapconfig} />
            </div>

          </main>
        </Fragment>
    }
    </Fragment>
  );
}

export default App;
