import React, { useState,useEffect,useRef} from "react";
import { loadModules } from 'esri-loader';
import MDReader from "./MDReader";
import { basemaptoggleWidget } from "./BasemapToggleWidget";

export default function EsriMap({ mapConfig }) {


    const [layer, setlayer] = useState(null);

    const mapContainerRef = useRef(null);

    useEffect(() => {

        //first load map, later load all widgets

        loadModules([
            'esri/WebMap',
            'esri/WebScene',
            'esri/views/MapView',
            'esri/views/SceneView'
        ]).then(([WebMap,WebScene,MapView,SceneView]) => {

            const webmap = new WebMap({
                portalItem: {
                id: mapConfig.portalId
            }});

            const webscene = new WebScene({
                portalItem: {
                id: mapConfig.portalId
            }});

            const view = mapConfig.type === 'Map' ? new MapView({container: mapContainerRef.current,map: webmap}) : new SceneView({container: mapContainerRef.current,map: webscene})


            mapConfig.widgets && mapConfig.widgets.forEach(widgetItem => {
                if(widgetItem.widget === 'BasemapToggle') {
                    basemaptoggleWidget(view,widgetItem.position);
                }
                else {
                loadModules([
                    "esri/widgets/Expand",
                    "esri/widgets/" + widgetItem.widget
                ]).then(([Expand, X]) => {

                    const widgetPosition = widgetItem.position ? widgetItem.position : "top-left"

                    const div = document.createElement("section")
                    div.className = 'custom-widget-'+widgetItem.widget

                    const widget = new X({
                        // container:div,
                        view: view,
                        label: widgetItem.label,
                        listItemCreatedFunction : widgetItem.widget === 'LayerList' ? defineActions : undefined,
                        activeTool: 'null'
                    });

                    if (widgetItem.expand === true) {

                        var WidgetExpand = new Expand({
                            container:div,
                            view: view,
                            content: widget,
                            expanded: widgetItem.expanded ? widgetItem.expanded: false,
                            expandTooltip: widgetItem.tooltip ? widgetItem.tooltip: widgetItem.widget
                        });
                        view.ui.add(WidgetExpand, { position: widgetPosition });
                    }
                    else {
                        view.ui.add(widget, { position: widgetPosition });
                    };

                    //move zoom widget
                    view.ui.move("zoom", "top-right");

                    widget.on("trigger-action", (event) => {
                        // put the layername into the state layer
                        setlayer(event.item.title);

                        //programmaticly open the modal by clicking on the label
                        document.getElementById('toggleModal').click();
                    })

                }).catch((err) => console.error(err));
            }
        });
        });

    }, [mapConfig]);

    function defineActions(event) {
        const item = event.item;
        item.actionsSections = [[{
            title: "Layer information",
            className: "esri-icon-description"
        }]]
    }

    console.log(layer);

    return <>
        <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />

        <label className="wb-modal__label" id="toggleModal" htmlFor="wb-modal__togglerlayerinfo">test</label>
        <wb-modal modaltitle={layer} modalid="layerinfo">
            {layer !== null &&
                <div className="rte wb-modal__body__content">
                    {/*
                        pass the layer to MDReader,
                        it returns the content of public/texts/[layer].md in the modal
                    */}
                <MDReader file={layer} />
                </div>
            }
        </wb-modal>
    </>;
}
